<script setup lang="ts">
import { useScroll, useWindowSize } from "@vueuse/core"
import { IconBrandDiscordFilled, IconBrandXFilled, IconBrandYoutube, IconHexagonFilled, IconWorld } from "@tabler/icons-vue"
import type { StyleValue } from "vue"
import IconApple from "~/assets/icons/brands/apple.svg"
import IconWindows from "~/assets/icons/brands/windows.svg"
import IconLinux from "~/assets/icons/brands/linux.svg"
import GridMode from "~/assets/icons/landing/grid-mode.svg?component"
import PreviewMode from "~/assets/icons/landing/preview-mode.svg?component"
import WaterfallView from "~/assets/icons/landing/waterfall-view.svg?component"
import FolderIcon from "~/assets/icons/landing/folder-icon.svg?component"
import CrystalBall from "~/assets/icons/landing/crystal-ball.svg?component"

definePageMeta({ layout: "landing" })

const page = ref<HTMLElement>()
const mockup = ref<HTMLElement>()
const mockupStart = ref<HTMLElement>()
const libraryOverlay = ref<HTMLImageElement>()

// Act I
const mockupActOneKeyframe10 = ref<HTMLElement>()
const mockupActOneKeyframe20 = ref<HTMLElement>()

// Act 2
const mockupAct2 = ref<HTMLElement>()
const mockupAct2Background = ref<HTMLElement>()
const mockupActTwoKeyframe10 = ref<HTMLElement>()
const mockupActTwoKeyframe20 = ref<HTMLElement>()
const mockupActTwoKeyframe30 = ref<HTMLElement>()
const mockupActTwoKeyframe40 = ref<HTMLElement>()

// Act 3
const mockupAct3 = ref<HTMLElement>()
const mockupAct3Background = ref<HTMLElement>()
const mockupActThreeKeyframe0 = ref<HTMLElement>()
const mockupActThreeKeyframe10 = ref<HTMLElement>()
const mockupActThreeKeyframe20 = ref<HTMLElement>()
const mockupActThreeKeyframe30 = ref<HTMLElement>()

// Video selection
const showVideo = useState("video-showing", () => undefined as "search" | "preview" | "sidebar" | "spaces" | "view-modes" | "waterfall" | undefined)

function yPos(ref?: HTMLElement) {
  return ref?.offsetTop || 0
}

function pos(idx: number, start: number, end: number) {
  return start + (idx * (end - start))
}

const { y } = useScroll(page, { })
const { width, height } = useWindowSize()
const [imgWidth, imgHeight] = [1600, 900]

const isMobile = ref(false)

function mockupStyleStartToActOne(): StyleValue {
  const startY = yPos(mockupStart.value)
  const endY = yPos(mockupActOneKeyframe10.value)
  const idx = Math.min(1, (y.value - startY) / (endY + 0.001 - startY))

  const transY = pos(idx, 0.75 * height.value, 0.75 * height.value)
  const mockupW = pos(idx, 7 / 5 * width.value, 0.8 * width.value)
  const mockupL = width.value * 0.5 - mockupW * 0.5

  return {
    "transform": `translate3d(0px, ${transY}px, 0px)`,
    "width": `${mockupW}px`,
    "left": `${mockupL}px`,
    "transform-origin": "top center",
  }
}

function libraryOverlayStyle(): StyleValue {
  const startY = yPos(mockupActOneKeyframe10.value) * 0.25
  const endY = yPos(mockupActOneKeyframe10.value) * 0.75
  const idx = Math.min(1, (y.value - startY) / (endY + 0.001 - startY))

  const opacity = pos(idx, 0, 1)

  return {
    opacity,
  }
}

function mockupStyleActOne_10_20(): StyleValue {
  const startY = yPos(mockupActOneKeyframe10.value)
  const endY = yPos(mockupActOneKeyframe20.value)
  const idx = Math.min(1, (y.value - startY) / (endY + 0.001 - startY))

  const mockupW = 0.8 * width.value
  const mockupL = width.value * 0.5 - mockupW * 0.5

  const mockupH = mockupW * (imgHeight / imgWidth)
  const mockupT = height.value * 0.5 - mockupH * 0.5
  const transY = pos(idx, 0.75 * height.value, mockupT)

  return {
    "transform": `translate3d(0px, ${transY}px, 0px)`,
    "width": `${mockupW}px`,
    "height": `${mockupH}px`,
    "left": `${mockupL}px`,
    "transform-origin": "top center",
  }
}

function mockupStyleActTwo_10_40(): StyleValue {
  const startY = yPos(mockupActTwoKeyframe10.value)
  const endY = yPos(mockupActTwoKeyframe20.value)
  const idx = Math.min(1, (y.value - startY) / (endY + 0.001 - startY))

  const mockupW = 0.8 * width.value
  const mockupL = width.value * 0.5 - mockupW * 0.5
  const transL = pos(idx, mockupL, width.value * 0.5 - mockupW)

  const mockupH = mockupW * (imgHeight / imgWidth)
  const mockupT = height.value * 0.5 - mockupH * 0.5

  return {
    "transform": `translate3d(0px, ${mockupT}px, 0px)`,
    "width": `${mockupW}px`,
    "height": `${mockupH}px`,
    "left": `${transL}px`,
    "transform-origin": "top center",
  }
}

function act2BackgroundPosition(): StyleValue {
  const startY = yPos(mockupActTwoKeyframe10.value)
  const sizeAct2 = mockupAct2.value?.getBoundingClientRect().height || 1
  const endY = startY + sizeAct2
  const endYH = endY - height.value

  const idxH = Math.max((y.value - startY) / (endYH - startY), 0)

  if (idxH <= 0)
    return {}
  if (idxH >= 1)
    return {
      position: "absolute",
      top: px(endYH),
    }

  return {
    position: "fixed",
    top: "0px",
  }
}

function act3BackgroundPosition(): StyleValue {
  const startY = yPos(mockupActThreeKeyframe10.value)
  const sizeAct3 = mockupAct3.value?.getBoundingClientRect().height || 1
  const endY = startY + sizeAct3
  const endYH = endY - height.value

  const idxH = Math.max((y.value - startY) / (endYH + 0.001 - startY), 0)

  if (idxH <= 0)
    return {}
  if (idxH >= 1)
    return {
      position: "absolute",
      top: px(endYH),
    }

  return {
    position: "fixed",
    top: "0px",
  }
}

function mockupStyleActThree0(): StyleValue {
  const startY = yPos(mockupActThreeKeyframe0.value)
  const endY = yPos(mockupActThreeKeyframe10.value)
  const idx = Math.min(1, (y.value - startY) / (endY + 0.001 - startY))

  const mockupW = 0.8 * width.value
  const transL = pos(idx, width.value * 0.5 - mockupW, width.value * 0.5)

  const mockupH = mockupW * (imgHeight / imgWidth)
  const mockupT = height.value * 0.5 - mockupH * 0.5
  const stopT = yPos(mockupActThreeKeyframe30.value) - height.value
  const transT = mockupT - Math.max(0, y.value - stopT)

  return {
    "transform": `translate3d(0px, ${transT}px, 0px)`,
    "width": `${mockupW}px`,
    "height": `${mockupH}px`,
    "left": `${transL}px`,
    "transform-origin": "top center",
  }
}

function mockupStyle() {
  if (isMobile.value)
    return
  if (!mockup.value)
    return { display: "none" }

  if (y.value < yPos(mockupActOneKeyframe10.value))
    return mockupStyleStartToActOne()

  if (y.value < yPos(mockupActTwoKeyframe10.value))
    return mockupStyleActOne_10_20()

  if (y.value < yPos(mockupActThreeKeyframe0.value))
    return mockupStyleActTwo_10_40()

  return mockupStyleActThree0()
}

watch(() => y.value, (newY) => {
  let newVideo = undefined as typeof showVideo.value
  if (newY < yPos(mockupActOneKeyframe20.value))
    newVideo = undefined
  else if (newY < yPos(mockupActTwoKeyframe20.value))
    newVideo = "search"
  else if (newY < yPos(mockupActTwoKeyframe30.value))
    newVideo = "view-modes"
  else if (newY < yPos(mockupActTwoKeyframe40.value))
    newVideo = "preview"
  else if (newY < yPos(mockupActThreeKeyframe10.value))
    newVideo = "waterfall"
  else if (newY < yPos(mockupActThreeKeyframe20.value))
    newVideo = "sidebar"
  else
    newVideo = "spaces"

  if (newVideo !== showVideo.value)
    showVideo.value = newVideo
})

function checkIfMobile() {
  if (width.value === undefined)
    isMobile.value = false
  else
    isMobile.value = width.value < 768
}

onMounted(async () => checkIfMobile())
watch(width, checkIfMobile)
</script>

<template>
  <landing-header :is-mobile />
  <div
    ref="page"
    class="landing-page"
    :class="{ desktop: !isMobile }"
    :style="{
      'background-position-y': px(-y),
    }"
  >
    <div
      ref="mockupStart"
      class="keyframe hero"
    >
      <div class="keyframe-label">
        Start
      </div>
      <h1 class="hero-headline">
        The Intelligent Cloud
        <br>
        File browser
      </h1>
      <h2 class="hero-subtitle">
        A smarter way to store, browse, search, and share your files — built for the generative age
      </h2>

      <div class="hero-cta">
        <nuxt-link
          to="/watch-demo"
          external
          target="_blank"
          class="hero-secondary-button"
        >
          <icon-brand-youtube
            :size="20"
            :stroke-width="2"
            class="inline"
          />
          <span>Watch Demo</span>
        </nuxt-link>
        <nuxt-link
          class="hero-button"
          to="https://waitlist.withpoly.com"
          external
          target="_blank"
        >
          <span>Join Waitlist --></span>
        </nuxt-link>
      </div>
      <div class="mt-8 inline-flex gap-1.5 text-center text-$global-t-default">
        <icon-apple class="h-6 w-6" />
        <icon-windows class="h-6 w-6" />
        <icon-linux class="h-6 w-6" />
        <icon-world :size="24" />
      </div>
    </div>
    <div v-if="isMobile" class="w-150% -mx-25%">
      <img
        src="~/assets/images/landing/feed.webp"
        class="w-full"
      >
    </div>
    <div
      ref="mockupActOneKeyframe10"
      class="keyframe search"
    >
      <div class="keyframe-label">
        Stop Zoom Out
      </div>

      <div class="search-headline">
        Poly is a smart file system for your digital creations
      </div>
      <div class="text-center">
        <div
          class="multi-modal"
        >
          <crystal-ball class="h-6 w-6" />
          <div>Multimodal AI</div>
        </div>
      </div>
      <div class="search-subtitle">
        Access and understand your image library like never
        before. Looking for something? Just ask in your own
        words, or search by image, or even <em>both</em> — Poly's AI actually
        understands your content to find what you need.
      </div>
    </div>
    <div v-if="isMobile">
      <video
        :width="1280"
        :height="720"

        loop
        autoplay
        muted
        playsinline
        class="mobile-mockup-video"
        poster="https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-08-29/Search.720.webp"
      >
        <source
          src="https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-08-29/Search.720.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div
      v-if="!isMobile"
      ref="mockupActOneKeyframe20"
      class="keyframe bg-stop-scroll-up h-300"
    >
      <div class="keyframe-label">
        Stop Scroll Up
      </div>
    </div>
    <div
      v-if="!isMobile"
      ref="mockupAct2"
      class="act-2"
    >
      <div
        ref="mockupAct2Background"
        :style="act2BackgroundPosition()"
        class="act-2-bg"
      />
      <div
        ref="mockupActTwoKeyframe10"
        class="keyframe second-bg h-200"
      >
        <div class="keyframe-label">
          Start Act 2 & Pan Right
        </div>
      </div>
      <div
        ref="mockupActTwoKeyframe20"
        class="keyframe h-[200vh] flex flex-col justify-center"
      >
        <div class="keyframe-label">
          Act 2 View Modes
        </div>
        <landing-scroll-reveal
          theme="amber"
          class="act-2-headline"
          :container="mockupActTwoKeyframe20"
          content="Beautiful layout modes that let you customize your view with any file property"
          :y
        >
          <grid-mode class="inline w-8" />
          <div>View Modes</div>
        </landing-scroll-reveal>
      </div>
      <div
        ref="mockupActTwoKeyframe30"
        class="keyframe h-[200vh] flex flex-col justify-center"
      >
        <div class="keyframe-label">
          Act 2 Preview Mode
        </div>
        <landing-scroll-reveal
          theme="amber"
          class="act-2-headline"
          :container="mockupActTwoKeyframe30"
          content="Lightning fast image previews with pan & zoom, along with all your generative metadata for you to view and edit"
          :y
        >
          <preview-mode class="inline w-8" />
          <div>Previews</div>
        </landing-scroll-reveal>
      </div>
      <div
        ref="mockupActTwoKeyframe40"
        class="keyframe h-[200vh] flex flex-col justify-center"
      >
        <div class="keyframe-label">
          Act 2 Waterfall
        </div>
        <landing-scroll-reveal
          theme="amber"
          class="act-2-headline"
          :container="mockupActTwoKeyframe40"
          content="Hyper-speedy scrolling through thousands of images, with a dedicated waterfall view to help you navigate"
          :y
        >
          <waterfall-view class="inline w-8" />
          <div>Waterfall View</div>
        </landing-scroll-reveal>
      </div>
    </div>
    <div v-if="isMobile" class="act-2-bg-mobile">
      <landing-mobile-reveal
        theme="amber"
        class="act-2-headline-mobile"
        content="Beautiful layout modes that let you customize your view with any file property"
        video="ViewModes"
      >
        <grid-mode class="inline w-6" />
        <div>View Modes</div>
      </landing-mobile-reveal>
      <landing-mobile-reveal
        theme="amber"
        class="act-2-headline-mobile"
        content="Lightning fast image previews with pan & zoom, along with all your generative metadata for you to view and edit"
        video="Preview"
      >
        <preview-mode class="inline w-6" />
        <div>Previews</div>
      </landing-mobile-reveal>
      <landing-mobile-reveal
        theme="amber"
        class="act-2-headline-mobile"
        content="Hyper-speedy scrolling through thousands of images, with a dedicated waterfall view to help you navigate"
        video="Waterfall"
      >
        <waterfall-view class="inline w-6" />
        <div>Waterfall View</div>
      </landing-mobile-reveal>
    </div>
    <div
      v-if="!isMobile"
      ref="mockupActThreeKeyframe0"
      class="keyframe h-[75vh]"
    >
      <div class="keyframe-label">
        Act 3 & Scroll to right
      </div>
    </div>
    <div
      v-if="!isMobile"
      ref="mockupAct3"
      class="act-3"
    >
      <div
        ref="mockupAct3Background"
        :style="act3BackgroundPosition()"
        class="act-3-bg"
      />
      <div
        ref="mockupActThreeKeyframe10"
        class="keyframe h-[200vh] flex flex-col justify-center"
      >
        <div class="keyframe-label">
          Act 3 Spaces
        </div>
        <landing-scroll-reveal
          theme="purple"
          class="act-3-headline"
          left
          :container="mockupActThreeKeyframe10"
          content="Add anything to your sidebar for quick access, such as files, folders, and even search queries"
          :y
        >
          <icon-hexagon-filled
            class="ml-0.5 mr-0.5 inline w-8 py-0.5"
            :size="32"
          />
          <div>Sidebar</div>
        </landing-scroll-reveal>
      </div>
      <div
        ref="mockupActThreeKeyframe20"
        class="keyframe h-[200vh] flex flex-col justify-center"
      >
        <div class="keyframe-label">
          Act 3 Public Sharing
        </div>
        <landing-scroll-reveal
          theme="purple"
          class="act-3-headline"
          left
          :container="mockupActThreeKeyframe20"
          title="Public Sharing"
          content="Instantly save and edit content with friends using shared folders, all with the same tagging, notes, and real-time updates"
          :y
        >
          <folder-icon class="ml-0.5 mr-1 inline w-8" />
          <div>Sharing</div>
        </landing-scroll-reveal>
      </div>
    </div>
    <div v-if="isMobile" class="act-3-bg-mobile">
      <landing-mobile-reveal
        theme="purple"
        class="act-3-headline-mobile"
        content="Add anything to your sidebar for quick access, such as groups of items, folders, spaces, and saved queries"
        video="Sidebar"
        right
      >
        <icon-hexagon-filled
          class="ml-0.5 mr-0.5 inline w-5 py-0.5"
          :size="20"
        />
        <div>Sidebar</div>
      </landing-mobile-reveal>
      <landing-mobile-reveal
        theme="purple"
        class="act-3-headline-mobile"
        content="Instantly save and edit content with friends, all with the same tagging, notes, and real-time updates"
        video="SharedFolders"
        right
      >
        <folder-icon class="ml-0.5 mr-1 inline w-5" />
        <div>Shared Folders</div>
      </landing-mobile-reveal>
    </div>
    <div
      ref="mockupActThreeKeyframe30"
      class="keyframe bento"
    >
      <div class="keyframe-label">
        Act 3 Finished
      </div>

      <div class="bento-headline">
        ...and so much more to help streamline your creativity
      </div>
      <div v-if="isMobile" class="bento-boxes-mobile">
        <div class="bento-box">
          <div class="title">
            <strong>Public Folders.</strong> Showcase your creations to the world with just a simple web link — no
            login required for others to browse or even search.
          </div>
          <img
            src="~/assets/images/landing/public-spaces-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title bottom-0 w-full">
            <strong>Desktop notifications.</strong> Receive real-time updates.
          </div>
          <img
            src="~/assets/images/landing/desktop-notifications-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title">
            <strong>Browser plugin.</strong> Auto-import from your favorite generative tools.
          </div>
          <img
            src="~/assets/images/landing/browser-plugin-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title bottom-0 w-full">
            <strong>Hyper-fast previews.</strong> Zoom and pan at your speed.
          </div>
          <img
            src="~/assets/images/landing/hyper-fast-previews-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title w-full">
            <strong>Peek any file.</strong> Instantly preview by tapping spacebar.
          </div>
          <img
            src="~/assets/images/landing/peek-any-file-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title bottom-0 w-full">
            <strong>Notes.</strong> Save text snippets that you can even search over.
          </div>
          <img
            src="~/assets/images/landing/notes-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title">
            <strong>Split view.</strong> Compare images side-by-side with metadata.
          </div>
          <img
            src="~/assets/images/landing/split-view-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title bottom-0 w-full">
            <strong>Folder drag-and-drop.</strong> Upload thousands of files, keeping your folder structure intact.
          </div>
          <img
            src="~/assets/images/landing/folder-drag-and-drop-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title bottom-0 w-full">
            <strong>Batch actions.</strong> Organize and edit files with ease.
          </div>
          <img
            src="~/assets/images/landing/batch-actions-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title w-full">
            <strong>Never hit refresh.</strong> Your files stay updated across all tabs and devices.
          </div>
          <img
            src="~/assets/images/landing/never-hit-refresh-mobile.webp"
          >
        </div>
        <div class="bento-box">
          <div class="title w-full">
            <strong>No conflicted copies, ever.</strong> Our innovative data model prevents sync issues.
          </div>
          <img
            src="~/assets/images/landing/no-conflicted-copies-mobile.webp"
          >
        </div>
      </div>
      <div v-if="!isMobile" class="bento-boxes">
        <div class="bento-row">
          <div class="bento-box w-11/16">
            <div class="title w-5/6">
              <strong>Public Folders.</strong> Showcase your creations to the world with just a simple web link — no
              login required for others to browse or even search.
            </div>
            <img
              src="~/assets/images/landing/public-spaces.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-5/16">
            <div class="title bottom-0 w-full">
              <strong>Desktop notifications.</strong> Receive real-time updates.
            </div>
            <img
              src="~/assets/images/landing/desktop-notifications.webp"
              loading="lazy"
            >
          </div>
        </div>
        <div class="bento-row">
          <div class="bento-box w-full">
            <div class="title w-1/2">
              <strong>Browser plugin.</strong> Auto-import from your favorite generative tools.
            </div>
            <img
              src="~/assets/images/landing/browser-plugin.webp"
              loading="lazy"
            >
          </div>
        </div>
        <div class="bento-row">
          <div class="bento-box w-1/3">
            <div class="title bottom-0 w-full">
              <strong>Hyper-fast previews.</strong> Zoom and pan at your speed.
            </div>
            <img
              src="~/assets/images/landing/hyper-fast-previews.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-1/3">
            <div class="title w-full">
              <strong>Peek any file.</strong> Instantly preview by tapping spacebar.
            </div>
            <img
              src="~/assets/images/landing/peek-any-file.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-1/3">
            <div class="title bottom-0 w-full">
              <strong>Notes.</strong> Save text snippets that you can even search over.
            </div>
            <img
              src="~/assets/images/landing/notes.webp"
              loading="lazy"
            >
          </div>
        </div>
        <div class="bento-row">
          <div class="bento-box w-1/2">
            <div class="title w-3/5">
              <strong>Split view.</strong> Compare images side-by-side with metadata.
            </div>
            <img
              src="~/assets/images/landing/split-view.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-1/2">
            <div class="title bottom-0 w-full">
              <strong>Folder drag-and-drop.</strong> Upload thousands of files, keeping your folder structure intact.
            </div>
            <img
              src="~/assets/images/landing/folder-drag-and-drop.webp"
              loading="lazy"
            >
          </div>
        </div>
        <div class="bento-row">
          <div class="bento-box w-1/3">
            <div class="title bottom-0 w-full">
              <strong>Batch actions.</strong> Organize and edit files with ease.
            </div>
            <img
              src="~/assets/images/landing/batch-actions.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-1/3">
            <div class="title w-full">
              <strong>Never hit refresh.</strong> Your files stay updated across all tabs and devices.
            </div>
            <img
              src="~/assets/images/landing/never-hit-refresh.webp"
              loading="lazy"
            >
          </div>
          <div class="bento-box w-1/3">
            <div class="title bottom-0 w-full">
              <strong>No conflicted copies, ever.</strong> Our innovative data model prevents sync issues.
            </div>
            <img
              src="~/assets/images/landing/no-conflicted-copies.webp"
              loading="lazy"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-headline">
        The intelligent file browser, available everywhere you are
      </div>
      <nuxt-link
        class="footer-cta"
        to="https://waitlist.withpoly.com"
        target="_blank"
      >
        <div>
          <span class="footer-button">
            Join Waitlist -->
          </span>
        </div>
        <div class="mt-8 inline-flex gap-1.5 text-center text-$global-t-default">
          <icon-apple class="h-6 w-6" />
          <icon-windows class="h-6 w-6" />
          <icon-linux class="h-6 w-6" />
          <icon-world :size="24" />
        </div>
      </nuxt-link>
    </div>
    <div class="footer-banner">
      <img
        src="/favicon.png"
        class="h-6 w-6"
      >
      <div class="footer-banner-right">
        <div class="love">
          Made with <strong>♥</strong> in San Francisco
        </div>
        <nuxt-link
          to="https://twitter.com/with_poly"
          target="_blank"
          external
        >
          <icon-brand-x-filled :size="18" />
        </nuxt-link>
        <nuxt-link
          to="/join-discord"
          target="_blank"
        >
          <icon-brand-discord-filled :size="18" />
        </nuxt-link>
      </div>
    </div>
    <div
      v-if="!isMobile"
      ref="mockup"
      class="mockup"
      :style="mockupStyle()"
    >
      <div v-show="!showVideo">
        <img
          ref="libraryOverlay"
          :style="libraryOverlayStyle()"
          src="~/assets/images/landing/library.webp"
          class="feed-image absolute z-100 w-full"
        >
        <img
          src="~/assets/images/landing/feed.webp"
          class="feed-image w-full"
        >
      </div>
      <landing-video
        v-show="showVideo === 'search'"
        name="Search"
      /><landing-video
        v-show="showVideo === 'preview'"
        name="Preview"
      /><landing-video
        v-show="showVideo === 'sidebar'"
        name="Sidebar"
      /><landing-video
        v-show="showVideo === 'spaces'"
        name="SharedFolders"
      /><landing-video
        v-show="showVideo === 'view-modes'"
        name="ViewModes"
      /><landing-video
        v-show="showVideo === 'waterfall'"
        name="Waterfall"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.desktop
  @apply relative h-screen overflow-y-auto

.landing-page
  @apply bg[#{$page-bg-primary}] overflow-x-hidden w-screen z-0
  background-image: url('https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-05-06/background-main.webp')
  background-size: 100% 150vh
  background-repeat: no-repeat

.act-2-bg
  @apply absolute w-full aspect-square bg-cover
  background-image: url('https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-05-06/bg-act-2.webp')
  background-repeat: no-repeat
  overflow: visible
.act-2-bg-mobile
  background-image: url('https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-05-06/bg-act-2.webp')
  background-repeat: no-repeat
  background-size: cover

.act-3-bg
  @apply absolute w-full aspect-square bg-cover
  background-image: url('https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-05-06/bg-act-3.webp')
  background-repeat: no-repeat
  overflow: visible
.act-3-bg-mobile
  background-image: url('https://d3b8el8c5kqwdk.cloudfront.net/assets/landing-page-2024-05-06/bg-act-3.webp')
  background-repeat: no-repeat
  background-size: cover

.mockup
  @apply pointer-events-none fixed top-0 w-full overflow-hidden border border-[#{$global-b-outline-page}] rounded-md shadow-2xl shadow-black/50 backdrop-blur-2xl backdrop-filter

.mobile-mockup-video
  @apply mx-auto h-full w-90% border border-[#{$global-b-outline-page}] rounded-md shadow-2xl shadow-black/50 pointer-events-auto

.keyframe
  // @apply border-t border-t-$global-amber-primary relative
  @apply relative
  .keyframe-label
    // @apply absolute top-1 left-1.5 text-$global-amber-primary text-2xs
    @apply hidden

.hero
  @apply flex flex-col items-center justify-center
  @apply h-[80vh]
  @apply md:h-[100vh] md:pb-[20vh]
  .hero-headline
    @apply text-center leading-snug tracking-wide
    @apply text-[32px] font-medium
    @apply md:text-[64px] md:font-normal
    @apply xl:text-[80px]
  .hero-subtitle
    @apply font-light text-center mt-8 mx-auto w-3/4 max-w-140
    @apply text-[18px]
    @apply md:text-[20px]
    @apply xl:text-[24px]
  .hero-cta
    @apply text-center mt-10 flex
  .hero-button
    @apply flex font-medium text-center rounded-xl bg-$global-blue-primary text-$global-t-default cursor-pointer inline-flex transform hover:scale-105 transition
    @apply xl:text-[18px]
    @apply md:px-6 md:py-4 md:text-[15px]
    @apply text-[14px] px-4 py-3
  .hero-secondary-button
    @apply font-medium text-center items-center gap-2 rounded-xl text-$global-t-default cursor-pointer inline-flex transform hover:scale-105 transition hover:bg-[#{$menu-bg-item-hover}]
    @apply xl:text-[18px]
    @apply md:px-6 md:py-4 md:text-[15px]
    @apply mr-4
    @apply text-[14px] px-4 py-3

.search
  @apply py-24
  @apply md:py-48
  .search-headline
    @apply font-normal text-center leading-snug tracking-wide xl:pb-6 w-5/6 mx-auto max-w-200
    @apply text-[32px] font-medium
    @apply md:text-[36px] md:font-normal
    @apply xl:text-[48px]
  .search-subtitle
    @apply font-light text-center mt-8 tracking-wide w-3/4 max-w-240 mx-auto
    @apply text-[18px]
    @apply xl:text-[28px]
  .multi-modal
    @apply mt-12 inline inline-flex items-center gap-1.5 rounded-lg bg-accent-strongest bg-opacity-50 py-2 pl-2 pr-3.5 font-normal tracking-wide text-[#{$global-t-default}] backdrop-blur-2xl backdrop-filter
    @apply text-[18px]
    @apply xl:text-[20px]

.act-2-headline
  @apply px-1/12 w-1/2 ml-auto xl:text-[36px] text-[28px] leading-tight font-medium tracking-wide
.act-2-headline-mobile
  @apply p-40px pt-100px text-[24px] leading-snug font-medium tracking-wide

.act-3-headline
  @apply w-1/2 xl:text-[36px] text-[28px] px-1/12 leading-tight font-medium tracking-wide
.act-3-headline-mobile
  @apply p-40px pt-100px text-[24px] leading-snug font-medium tracking-wide

.bento
  @apply py-20
  @apply md:py-40
  .bento-headline
    @apply text-center leading-snug tracking-wide text-shadow-xl text-shadow-color-black/20 w-5/6 max-w-200 mx-auto font-normal
    @apply text-[24px] pb-6
    @apply md:text-[32px] md:pb-20
    @apply xl:text-[40px] xl:font-medium xl:pb-20
  .bento-boxes
    @apply mx-auto w-screen-lg flex flex-col gap-4
    .bento-row
      @apply flex gap-4
      .bento-box
        @apply h-83 bg-[#{$page-bg-primary}] rounded-lg border border-[#{$global-b-outline-page}] overflow-hidden text-[20px] text-[#{$global-t-secondary}] relative font-normal
        .title
          @apply py-3 px-4 absolute
          strong
            @apply font-normal text-[#{$global-t-default}]
        & > img
          @apply object-cover min-w-full min-h-full
  .bento-boxes-mobile
    @apply p-12 flex flex-col gap-8
    .bento-box
      @apply aspect-ratio-[7/8] bg-[#{$page-bg-primary}] rounded-lg border border-[#{$global-b-outline-page}] overflow-hidden text-[20px] text-[#{$global-t-secondary}] relative font-normal
      .title
        @apply py-3 px-4 absolute
        strong
          @apply font-normal text-[#{$global-t-default}]

.footer
  @apply flex flex-col items-center justify-center
  @apply pb-20
  @apply md:pb-40
  .footer-headline
    @apply text-[28px] font-normal text-center leading-snug tracking-wide w-5/6 max-w-200
    @apply md:text-[36px]
    @apply xl:text-[48px]
  .footer-subtitle
    @apply xl:text-[28px] text-[18px] font-light text-center mt-8 tracking-wide
  .footer-cta
    @apply text-center mt-10
  .footer-button
    @apply xl:text-[18px] text-[14px] font-medium text-center px-6 py-3 rounded-lg bg-$global-blue-primary text-$global-t-default cursor-pointer inline-flex transform hover:scale-105 transition

.footer-banner
  @apply bg-[#{$page-bg-primary}] w-full flex items-center px-3 h-14
  .footer-banner-right
    @apply ml-auto h-full flex items-center gap-3 mr-2
    .love
      @apply font-normal text-xs
      & strong
        @apply font-normal text-$global-violet-primary
</style>
